<template>
    <div id="supervisor-family">

        <b-modal hide-footer size="sm" ref="family-modal" title="Family">
            <b-row>

                <b-col cols="12">
                    <b-form-group label="Family Description">
                        <b-form-input v-model="family.description" placeholder="Family" ref="familyDescription"
                            @keyup.enter="saveData" />
                    </b-form-group>
                </b-col>

            </b-row>

            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button @click="saveData()" variant="relief-success">
                        <feather-icon icon="PocketIcon" class="mr-50" />
                        <span class="align-middle">Save</span>
                    </b-button>
                </b-col>

            </b-row>

        </b-modal>

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Families List" @refresh="loadData" :add="true" @plusClicked="showModal"
                    @cellDoubleClicked="showModal" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Code",
                        field: "code",
                    },
                    {
                        headerName: "Description",
                        field: "description",
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.showModal, icon: "read_more" },
                        width: 100
                    },
                ],
                rows: []
            },
            family: {
                code: "",
                description: ""
            },
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.loadData();
    },
    methods: {
        showModal(data) {
            if (data == undefined) {
                this.family = {
                    code: "",
                    description: ""
                }
            } else {
                if (data.code)
                    this.family = {
                        code: data.code,
                        description: data.description
                    }
                else
                    this.family = {
                        code: data.data.code,
                        description: data.data.description
                    }
            }
            this.$refs['family-modal'].show();
            setTimeout(() => {
                this.$refs["familyDescription"].focus();
            }, 400);
        },
        async saveData() {
            this.$refs['family-modal'].hide();
            this.showLoading = true;
            let response = await this.$http.post("_item/family", this.family).catch(() => this.showLoading = false);
            this.agGrid.rows = response.data;
            this.showLoading = false;
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("_item/family").catch(() => this.showLoading = false);
            this.agGrid.rows = response.data;
            this.showLoading = false;
        }
    },
}
</script>
<style lang="scss" scoped></style>